export const primePostData = [
  // later on add section inside for pctures as well
  {
    user: "Trey Barnes",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2022-07-12",
    id: 1,
    status: "Just Checking",
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Test Video to show the Dynamic changes in Database",
      description:
        "This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 1,
      comments: [
        {
          user: "Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2022-07-12",
          id: 1,
          comment: "This is not that pretty cool",
          like: true,
        },
        {
          user: "Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2022-07-13",
          id: 2,
          comment: "This is is not cool",
          like: false,
        },
        {
          user: "Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2022-07-14",
          id: 3,
          comment: "This is freaking amazing",
          like: true,
        },
        {
          user: "Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2022-07-15",
          id: 4,
          comment: "This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
  {
    user: "Trey Barnes 2",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2021-07-12",
    id: 12,
    status: "Just Checking2",
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "2222Test Video to show the Dynamic changes in Database",
      description:
        "2222This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 2,
      comments: [
        {
          user: "2Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2021-07-12",
          id: 1,
          comment: "2This is not that pretty cool",
          like: true,
        },
        {
          user: "2Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2021-07-13",
          id: 2,
          comment: "2This is is not cool",
          like: false,
        },
        {
          user: "2Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2021-07-14",
          id: 3,
          comment: "2This is freaking amazing",
          like: true,
        },
        {
          user: "2Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2021-07-15",
          id: 4,
          comment: "2This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
  {
    user: "Trey Barnes",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2022-07-12",
    id: 1,
    status: "Just Checking",
    uploadedVid: {
      video: require("../../../Assets/Videos/video3.mp4"),
      title: "Test Video to show the Dynamic changes in Database",
      description:
        "This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 1,
      comments: [
        {
          user: "Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2022-07-12",
          id: 1,
          comment: "This is not that pretty cool",
          like: true,
        },
        {
          user: "Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2022-07-13",
          id: 2,
          comment: "This is is not cool",
          like: false,
        },
        {
          user: "Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2022-07-14",
          id: 3,
          comment: "This is freaking amazing",
          like: true,
        },
        {
          user: "Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2022-07-15",
          id: 4,
          comment: "This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
  {
    user: "Trey Barnes 2",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2021-07-12",
    id: 12,
    status: "Just Checking2",
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "2222Test Video to show the Dynamic changes in Database",
      description:
        "2222This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 2,
      comments: [
        {
          user: "2Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2021-07-12",
          id: 1,
          comment: "2This is not that pretty cool",
          like: true,
        },
        {
          user: "2Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2021-07-13",
          id: 2,
          comment: "2This is is not cool",
          like: false,
        },
        {
          user: "2Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2021-07-14",
          id: 3,
          comment: "2This is freaking amazing",
          like: true,
        },
        {
          user: "2Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2021-07-15",
          id: 4,
          comment: "2This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
  {
    user: "Trey Barnes",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2022-07-12",
    id: 1,
    status: "Just Checking",
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "Test Video to show the Dynamic changes in Database",
      description:
        "This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 1,
      comments: [
        {
          user: "Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2022-07-12",
          id: 1,
          comment: "This is not that pretty cool",
          like: true,
        },
        {
          user: "Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2022-07-13",
          id: 2,
          comment: "This is is not cool",
          like: false,
        },
        {
          user: "Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2022-07-14",
          id: 3,
          comment: "This is freaking amazing",
          like: true,
        },
        {
          user: "Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2022-07-15",
          id: 4,
          comment: "This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
  {
    user: "Trey Barnes 2",
    pic: require("../../../Assets/Images/testUsers/User0.jpeg"),
    postedDate: "2021-07-12",
    id: 12,
    status: "Just Checking2",
    uploadedVid: {
      video: require("../../../Assets/Videos/video3.mp4"),
      title: "2222Test Video to show the Dynamic changes in Database",
      description:
        "2222This video was taken from online. It has a bunch of beacons of whatever they are called. I amd showing this because people hardly ever read the description but NEVERTHELESS!",
      number: 2,
      comments: [
        {
          user: "2Jackson Tucker",
          pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
          postedDate: "2021-07-12",
          id: 1,
          comment: "2This is not that pretty cool",
          like: true,
        },
        {
          user: "2Tomas Dillard",
          pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
          postedDate: "2021-07-13",
          id: 2,
          comment: "2This is is not cool",
          like: false,
        },
        {
          user: "2Annie Frank",
          pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
          postedDate: "2021-07-14",
          id: 3,
          comment: "2This is freaking amazing",
          like: true,
        },
        {
          user: "2Cedric Langley",
          pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
          postedDate: "2021-07-15",
          id: 4,
          comment: "2This the best thing i ave ever seen! DOPE!!!",
          like: true,
        },
      ],
    },
  },
];
export const userProgressData = [
  {
    user: "Justin Williams",
    pic: require("../../../Assets/Images/user.jpeg"),
    postedDate: "2022-03-12",
    id: 1,
    status: "Just Checking",
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Test Video for User Weekly Progress Video",
      description:
        "This video was taken from online. It is a random due beating some drums by a body of water. Probably high... Likely High",
      number: 1,
    },
  },
];
export const textData = [
  {
    user: "Jackson Tucker",
    pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
    postedDate: "2022-01-04",
    id: "1",
    title: "one",
    status: "this is the test to see if what the user types appears",
  },
  {
    user: "Tomas Dillard",
    pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
    postedDate: "2012-07-04",
    id: "2",
    title: "two",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Annie Frank",
    pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
    postedDate: "2022-02-04",
    id: "3",
    title: "three",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Cedric Langley",
    pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
    postedDate: "2022-07-06",
    id: "4",
    title: "four",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Addie Moore",
    pic: require("../../../Assets/Images/testUsers/User5.jpeg"),
    postedDate: "2021-11-04",
    id: "5",
    title: "five",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Meredith Black",
    pic: require("../../../Assets/Images/testUsers/User6.jpeg"),
    postedDate: "2022-06-01",
    id: "6",
    title: "six",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Goku Myoshie",
    pic: require("../../../Assets/Images/testUsers/User7.jpeg"),
    postedDate: "2022-06-02",
    id: "7",
    title: "seven",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Waylan Max",
    pic: require("../../../Assets/Images/testUsers/User8.jpeg"),
    postedDate: "2022-05-04",
    id: "8",
    title: "eight",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Jared Crouch",
    pic: require("../../../Assets/Images/testUsers/User9.jpeg"),
    postedDate: "2022-03-04",
    id: "9",
    title: "nine",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Jason Vorhees",
    pic: require("../../../Assets/Images/testUsers/User10.jpeg"),
    postedDate: "2022-02-04",
    id: "10",
    title: "ten",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Wally Pixy",
    pic: require("../../../Assets/Images/testUsers/User11.jpeg"),
    postedDate: "2022-01-04",
    id: "11",
    title: "eleven",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Matt Porky",
    pic: require("../../../Assets/Images/testUsers/User12.jpeg"),
    postedDate: "2021-12-04",
    id: "12",
    title: "twelve",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Jim Witwhipkey",
    pic: require("../../../Assets/Images/testUsers/User13.jpeg"),
    postedDate: "2020-07-14",
    id: "13",
    title: "thriteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Sarah Richie",
    pic: require("../../../Assets/Images/testUsers/User14.jpeg"),
    postedDate: "2019-07-04",
    id: "14",
    title: "fourteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Chadayney Poory",
    pic: require("../../../Assets/Images/testUsers/User15.jpeg"),
    postedDate: "2022-07-01",
    id: "15",
    title: "fifteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Chris Chapman",
    pic: require("../../../Assets/Images/testUsers/User16.jpeg"),
    postedDate: "2021-11-04",
    id: "16",
    title: "sixteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "David Racer",
    pic: require("../../../Assets/Images/testUsers/User17.jpeg"),
    postedDate: "2021-12-25",
    id: "17",
    title: "seventeen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Alex Kramer",
    pic: require("../../../Assets/Images/testUsers/User18.jpeg"),
    postedDate: "2022-02-14",
    id: "18",
    title: "eighteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Alexis Texas",
    pic: require("../../../Assets/Images/testUsers/User19.jpeg"),
    postedDate: "2010-07-04",
    id: "19",
    title: "ninteen",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
  {
    user: "Scott Scotch",
    pic: require("../../../Assets/Images/testUsers/User20.jpeg"),
    postedDate: "2002-07-04",
    id: "20",
    title: "twenty",
    status: "this is the test to see if what the user types appears",
    verified: false,
    description: "",
  },
];
export const photoData = [
  {
    user: "Jackson Tucker",
    pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
    postedDate: "2022-01-04",
    id: "1",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
  {
    user: "Tomas Dillard",
    pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
    postedDate: "2012-07-04",
    id: "2",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
  {
    user: "Annie Frank",
    pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
    postedDate: "2022-02-04",
    id: "3",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
  {
    user: "Cedric Langley",
    pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
    postedDate: "2022-07-06",
    id: "4",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
  {
    user: "Addie Moore",
    pic: require("../../../Assets/Images/testUsers/User5.jpeg"),
    postedDate: "2021-11-04",
    id: "5",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
  {
    user: "Meredith Black",
    pic: require("../../../Assets/Images/testUsers/User6.jpeg"),
    postedDate: "2022-06-01",
    id: "6",
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    images: [
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image1.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image2.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image3.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image4.png"),
          name: "Pic1",
          number: 2,
        },
      },
      {
        uploadedPic: {
          image: require("../../../Assets/Images/testImages/image5.png"),
          name: "Pic1",
          number: 2,
        },
      },
    ],
  },
];
export const videoData = [
  {
    user: "Adam West",
    pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
    postedDate: "2022-03-12",
    id: 1,
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    videos: [
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video2.mp4"),
          title: "Vid1",
          description:
            "This is a description of the fake video file that we have here",
          number: 1,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video.mp4"),
          title: "Vid2",
          description:
            "This is a description of the fake video file that we have here",
          number: 2,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video2.mp4"),
          title: "Vid3",
          description:
            "This is a description of the fake video file that we have here",
          number: 3,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video.mp4"),
          title: "Vid4",
          description:
            "This is a description of the fake video file that we have here",
          number: 4,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video2.mp4"),
          title: "Vid5",
          description:
            "This is a description of the fake video file that we have here",
          number: 5,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video.mp4"),
          title: "Vid6",
          description:
            "This is a description of the fake video file that we have here",
          number: 6,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video2.mp4"),
          title: "Vid7",
          description:
            "This is a description of the fake video file that we have here",
          number: 7,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video.mp4"),
          title: "Vid8",
          description:
            "This is a description of the fake video file that we have here",
          number: 8,
        },
      },
    ],
  },
];
export const episodeData = [
  {
    user: "Annie Frank",
    pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
    postedDate: "2022-02-04",
    id: 1,
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    episodes: [
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video.mp4"),
          title: "Episode 1: The March of the Coder",
          number: 1,
        },
      },
      {
        uploadedVid: {
          video: require("../../../Assets/Videos/video2.mp4"),
          title: "Vid2",
          number: 2,
        },
      },
    ],
  },
];
export const musicData = [
  {
    user: "Annie Frank",
    pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
    postedDate: "2022-02-04",
    id: 1,
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    albums: [
      {
        album: {
          name: "Second Album",
          artwork: require("../../../Assets/Images/TestArtWork2.jpeg"),
          tracks: [
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 1: The March of the Coder",
              trackNum: 1,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 2: The March of the Coder",
              trackNum: 2,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 3: The March of the Coder",
              trackNum: 3,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 4: The March of the Coder",
              trackNum: 4,
            },
          ],
        },
      },
      {
        album: {
          name: "First Album",
          artwork: require("../../../Assets/Images/TestArtWork.jpeg"),

          tracks: [
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 1: The March of the Coder",
              trackNum: 1,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 2: The March of the Coder",
              trackNum: 2,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 3: The March of the Coder",
              trackNum: 3,
            },
            {
              song: require("../../../Assets/Music/Recording.m4a"),
              artist: "Track Artist feat. Lil Feature",
              title: "Track 4: The March of the Coder",
              trackNum: 4,
            },
          ],
        },
      },
    ],
  },
];
export const storeData = [
  {
    user: "Adam West",
    pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
    postedDate: "2022-03-12",
    id: 1,
    status: "Just Checking",
    // figure way to have multiple pictures from adding
    store: [
      {
        item: {
          itemName: "Golden Microphone",
          itemPic: require("../../../Assets/Images/Microphone.jpg"),
          itemDescription: "This item is a golden Microphone",
          price: 50,
          number: 1,
        },
      },
      {
        item: {
          itemName: "Golden Guitar",
          itemPic: require("../../../Assets/Images/Guitar.jpeg"),
          itemDescription: "This item is a golden Guitar",
          price: 500,
          number: 2,
        },
      },
      {
        item: {
          itemName: "Golden Drum Set",
          itemPic: require("../../../Assets/Images/Drums.jpeg"),
          itemDescription: "This item is a golden Drum Set",
          price: 675,
          number: 2,
        },
      },
    ],
  },
];
export const videoData2 = [
  // figure way to have multiple pictures from adding
  {
    user: {
      name: "Jackson Tucker",
      pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "Vid1",
      description:
        "This is a description of the fake video file that we have here",
      number: 1,
      postedDate: "2022-03-12",
    },
  },
  {
    user: {
      name: "Tomas Dillard",
      pic: require("../../../Assets/Images/testUsers/User2.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Vid2",
      description:
        "This is a description of the fake video file that we have here",
      number: 2,
      postedDate: "2022-01-04",
    },
  },
  {
    user: {
      name: "Annie Frank",
      pic: require("../../../Assets/Images/testUsers/User3.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "Vid3",
      description:
        "This is a description of the fake video file that we have here",
      number: 3,
      postedDate: "2012-07-04",
    },
  },
  {
    user: {
      name: "Cedric Langley",
      pic: require("../../../Assets/Images/testUsers/User4.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Vid4",
      description:
        "This is a description of the fake video file that we have here",
      number: 4,
      postedDate: "2022-02-04",
    },
  },
  {
    user: {
      name: "Addie Moore",
      pic: require("../../../Assets/Images/testUsers/User5.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "Vid5",
      description:
        "This is a description of the fake video file that we have here",
      number: 5,
      postedDate: "2022-07-06",
    },
  },
  {
    user: {
      name: "Meredith Black",
      pic: require("../../../Assets/Images/testUsers/User6.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Vid6",
      description:
        "This is a description of the fake video file that we have here",
      number: 6,
      postedDate: "2021-11-04",
    },
  },
  {
    user: {
      name: "Adam West",
      pic: require("../../../Assets/Images/testUsers/User1.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video2.mp4"),
      title: "Vid7",
      description:
        "This is a description of the fake video file that we have here",
      number: 7,
      postedDate: "2022-06-01",
    },
  },
  {
    user: {
      name: "Goku Myoshie",
      pic: require("../../../Assets/Images/testUsers/User7.jpeg"),
      id: 1,
    },
    uploadedVid: {
      video: require("../../../Assets/Videos/video.mp4"),
      title: "Vid8",
      description:
        "This is a description of the fake video file that we have here",
      number: 8,
      postedDate: "2022-05-04",
    },
  },
];
