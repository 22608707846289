import { Link } from "react-router-dom";
import Users from "./User";

const EditorPage = () => {
  return (
    <div className="page">
      <div
      // className={"home-container " + (modal ? "user-true" : "user-false")}
      // ref={widthRef}
      >
        <h1>This is the Editor Page</h1>
      </div>
    </div>
  );
};

export default EditorPage;
